<template>
  <van-card>
    <template #title>
      <span class="name">{{ doctorInfo.doctorName }}</span>
      <br />
      <span class="job">{{ doctorInfo.title }}</span>
      <span class="dept">{{ doctorInfo.deptName }}</span>
      <br />
    </template>
    <template #thumb>
      <van-image
        v-if="doctorInfo.doctorPicUrl !== ''"
        round
        width="1.5rem"
        height="1.5rem"
        fit="contain"
        :src="'data:image/png;base64,' + doctorInfo.doctorPicUrl"
      />
      <van-image
        v-else
        round
        width="1.5rem"
        height="1.5rem"
        :src="require('@/assets/dept_images/Doctor_photo.png')"
      />
    </template>
  </van-card>
</template>

<script>
  import { getDoctorInfo } from '@/api/basic/doctor/doctor'

  export default {
    name: 'DoctorInfo',
    props: {
      doctorCode: { type: String, default: () => '' },
      doctorName: { type: String, default: () => '' },
      deptName: { type: String, default: () => '' },
    },
    data() {
      return {
        doctorInfo: {
          doctorCode: this.doctorCode,
          doctorName: this.doctorName,
          deptName: this.deptName,
          doctorPicUrl: '',
        },
      }
    },
    created() {
      this.getDoctorInfo()
    },
    methods: {
      //获取医生
      async getDoctorInfo() {
        const { success, data } = await getDoctorInfo({
          DoctorCode: this.doctorInfo.doctorCode,
        })
        if (success) {
          this.doctorInfo = data
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .van-card {
    width: 95%;
    margin: 0.4rem auto auto;
    border-radius: 10px;
    background: #fff;
    font-size: 0.426rem;
    line-height: 1.5;
    .van-card__thumb {
      width: 59px;
      height: 1.5rem;
    }
    .van-card__content {
      width: 59px;
      min-height: 1.5rem;
    }
    .name {
      font-weight: bold;
    }
    .job {
      font-size: 0.38rem;
      color: #616161;
      margin-right: 1em;
    }
    .dept {
      font-size: 0.38rem;
      color: #616161;
    }
  }
  .grid-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr, 2fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;

    div:nth-child(odd) {
      color: #9e9e9e;
      grid-column: 1;
      font-size: 0.4rem;
    }

    div:nth-child(even) {
      grid-column: 2;
    }

    .time {
      color: #f0994c;
    }
  }
</style>
