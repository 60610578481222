<template>
  <div>
    <DoctorInfo
      :dept-name="search.deptName"
      :doctor-code="doctorInfo.doctorCode"
      :doctor-name="search.doctorName"
    />
    <div class="sourceList">
      <div class="title">预约信息</div>
      <div class="list">
        <div class="grid-table">
          <div>就诊科室</div>
          <div>{{ search.deptName }}</div>
          <div>就诊人</div>
          <div>{{ userInfo.name }}</div>
          <div>就诊时间</div>
          <div class="time">
            {{ search.date }} {{ beginTime }}~{{ endTime }}
          </div>
          <div>号码</div>
          <div>{{ code }}号</div>
        </div>
      </div>
    </div>
    <div style="margin: 16px">
      <van-button round block type="info" native-type="submit" @click="cancel">
        取消预约
      </van-button>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import DoctorInfo from '@/components/DoctorInfo'
  import { cancelAppointment } from '@/api/his/his'
  import { Dialog } from 'vant'
  import { cancelMessage } from '@/config'

  export default {
    name: 'Cancel',
    components: { DoctorInfo },
    data() {
      return {
        beginTime: '',
        endTime: '',
        code: '',

        doctorInfo: {
          doctorCode: '',
          doctorPicUrl: '',
        },
        userInfo: {
          name: '',
        },

        search: {
          idNo: '',
          openId: '',
          number: '',
          reason: '',
          name: '',
          doctorName: '',
          deptName: '',
          date: '',
          cardNo: '',
          isNew: 0,
        },
      }
    },
    created() {
      let query = this.$route.query
      this.doctorInfo.doctorCode = this.$Base64.decode(query.doctorCode)
      this.beginTime = this.$Base64.decode(query.beginTime)
      this.endTime = this.$Base64.decode(query.endTime)
      this.code = this.$Base64.decode(query.code)
      this.search.date = this.$Base64.decode(query.date)
      this.search.number = this.$Base64.decode(query.number)
      this.search.deptName = this.$Base64.decode(query.deptName)
      this.search.doctorName = this.$Base64.decode(query.doctorName)
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.search.openId = store.getters['user/openId']
      if (userInfo != null) {
        this.userInfo.name = userInfo.name
        this.search.name = userInfo.name
        this.search.idNo = userInfo.card_No
        this.search.cardNo = userInfo.number
        this.search.isNew = userInfo.isNew
      }
    },

    methods: {
      async cancel() {
        Dialog.confirm({
          title: '确认',
          message: cancelMessage,
        }).then(async () => {
          const { success, msg } = await cancelAppointment(this.search)
          Dialog.alert({
            message: msg,
          }).then(() => {
            this.$router.replace('/appointment')
          })
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .sourceList {
    width: 95%;
    margin: auto;
    margin-top: 0.4rem;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    .title {
      font-weight: bolder;
      padding: 0.426rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
    }
    .title::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 1em;
      border-radius: 2px;
      margin-right: 0.5em;
      background: #1691fe;
      overflow: hidden;
    }
    .list {
      padding: 15px;
      .van-cell {
        padding: 0.3rem 0;
        overflow: hidden;
        font-size: 0.4rem;
        span {
          margin-right: 1em;
        }

        span:nth-child(2) {
          font-weight: bold;
        }
        span:nth-child(3) {
          font-weight: bold;
          color: #1691fe;
        }

        .number {
          background: #1691fe;
          width: fit-content;
          padding: 0 15px;
          color: #fff;
          line-height: 30px;
          border-radius: 15px;
          float: right;
        }
      }
    }
  }
  .grid-table {
    display: grid;
    grid-template-columns: repeat(2, 1fr, 2fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;

    div:nth-child(odd) {
      color: #9e9e9e;
      grid-column: 1;
      font-size: 0.4rem;
    }

    div:nth-child(even) {
      grid-column: 2;
    }

    .time {
      color: #f0994c;
    }
  }
</style>
